import React from 'react'


function Contect() {
    return (
        <div id='contact'>
            <h1>CONTACT US</h1>
            <form action='https://formspree.io/f/mrgvevwr' method='post'>
            <input type='Name' placeholder="First Name" required />
            <input type='Email' placeholder="Email" required />
            <textarea placeholder='Write Here......' />
            <input type='submit' value='send' />
            </form>            
        </div>
    )
}

export default Contect