import React from 'react'

function Offer() {
    return (
        <div id='presentaion'>
            <div className='pr-heading'>
                <h1> A Big <span>OFFER</span>FOR THIS SUMMER</h1>
                <p className='details'>Lorem ipsum dolor sit amet consectetur adipisicing.</p>
                <div className='pr-btns'>
                    <a href='#' className='pr-btn'>JOIN NOW</a>
                </div>
            </div>
        </div>
    )
}

export default Offer
